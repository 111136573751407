@import url('https://p.typekit.net/p.css?s=1&k=ahh4bnt&ht=tk&f=17154.17156.17158.34307&a=3777302&app=typekit&e=css');

@font-face {
    font-family: 'quatro';
    src: url('https://use.typekit.net/af/25b75a/00000000000000003b9afc06/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
            format('woff2'),
        url('https://use.typekit.net/af/25b75a/00000000000000003b9afc06/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
            format('woff'),
        url('https://use.typekit.net/af/25b75a/00000000000000003b9afc06/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
            format('opentype');
    font-display: swap;
    font-style: normal;
    font-weight: 700;
}

@font-face {
    font-family: 'quatro';
    src: url('https://use.typekit.net/af/b816f6/00000000000000003b9afc02/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3')
            format('woff2'),
        url('https://use.typekit.net/af/b816f6/00000000000000003b9afc02/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3')
            format('woff'),
        url('https://use.typekit.net/af/b816f6/00000000000000003b9afc02/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3')
            format('opentype');
    font-display: swap;
    font-style: normal;
    font-weight: 500;
}

@font-face {
    font-family: 'quatro';
    src: url('https://use.typekit.net/af/e2ef1f/00000000000000003b9afc01/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
            format('woff2'),
        url('https://use.typekit.net/af/e2ef1f/00000000000000003b9afc01/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
            format('woff'),
        url('https://use.typekit.net/af/e2ef1f/00000000000000003b9afc01/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
            format('opentype');
    font-display: swap;
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: 'quatro';
    src: url('https://use.typekit.net/af/d9a6aa/00000000000000003b9afbff/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
            format('woff2'),
        url('https://use.typekit.net/af/d9a6aa/00000000000000003b9afbff/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
            format('woff'),
        url('https://use.typekit.net/af/d9a6aa/00000000000000003b9afbff/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
            format('opentype');
    font-display: swap;
    font-style: normal;
    font-weight: 300;
}

.tk-quatro {
    font-family: 'quatro', sans-serif;
}

body {
    margin: 0;
    font-family: quatro, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding: 0;
    line-height: 1.5em;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    font-display: swap;
}

#root {
    flex-grow: 1;
    background-color: #000;
}
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

h1,
h2 {
    color: #0c0c0c;
}

a#remove-update {
    color: #000;
}

a {
    color: #000;
    text-decoration: underline;
}

p {
    font-size: 17px;
    white-space: pre-line;
}

select {
    width: 100%;
    font-size: 16px;
    padding: 8px 12px;
    box-sizing: border-box;
}

input,
select {
    box-sizing: border-box;
    border: 1px solid rgba(105, 201, 237, 1);
    color: #000;
    font-size: 16px;
    margin: 10px auto 0;
    border-radius: 3px;
    height: 40px;
    padding: 10px 12px;
}

.nav {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.nav-link {
    display: block;
    padding: 0.5rem 1rem;
}

.nav-link:hover,
.nav-link:focus {
    text-decoration: none;
}

.nav-link.disabled {
    color: #6c757d;
}

.nav-tabs {
    border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-item {
    margin-bottom: -1px;
}

.nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
    border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
    color: #6c757d;
    background-color: transparent;
    border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.nav-pills .nav-link {
    border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
    color: #fff;
    background-color: #007bff;
}

.nav-fill .nav-item {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    text-align: center;
}

.nav-justified .nav-item {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    text-align: center;
}

.tab-content > .tab-pane {
    display: none;
}

.tab-content > .active {
    display: block;
}

.footer {
    text-align: center;
    width: 100%;
    float: left;
    background-color: #000;
}

.footer-social-icon {
    width: 39px;
    margin: auto;
}

.footer-social-icon img {
    max-width: 100%;
}

footer {
    height: auto;
    display: flex;
    flex-direction: row;
    text-align: center;
    width: 100%;
    flex-shrink: 0;
    color: #FFFFFF;
}
.footer-links {
    font-size: 14px;
    text-align: center;
}

.footer-links a {
    color: #FFFFFF;
    padding-right: 0.5em;
}
.footer-links:last-of-type {
    padding-right: 0;
}

.footer-links-light a:hover {
    color: #FFFFFF;
}

.footer-links-dark a:hover {
    color: #FFFFFF;
}

.footer-social-link {
    padding: 5px;
    color: #FFFFFF;
}
.footer-social-link-text {
    font-size: 13px;
    color: #FFFFFF;
    text-align: center;
}
.microblog {
    padding: 4px;
}
.copyright-notice {
    padding: 2em 20% 4em 20%;
}

@media (max-width: 420px) {
    .copyright-notice {
        padding: 20px;
    }
}

.copyright-notice p {
    text-align: center;
    color: #FFFFFF !important;
    font-size: 12px;
    margin: 0 auto;
    max-width: 768px;
}

.store-icon {
    max-width: 85%;
    height: auto;
    padding-left: 30px;
}

header {
    height: 65px;
}

.social-links {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 30px;
}
.fb-image {
    width: 30px;
    margin: 8px 5px 2px;
}
.decorator-icon {
    display: block;
    margin-right: 10px;
}
.mr-auto,
.mx-auto {
    margin-right: auto !important;
}

@keyframes ld-spin {
    0% {
        transform: rotate(0deg);
        animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    50% {
        transform: rotate(180deg);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    100% {
        transform: rotate(360deg);
    }
}

.ld-ring {
    width: 1em;
    height: 1em;
    position: relative;
    color: inherit;
    display: inline-block;
}

.ld-ring:after {
    position: absolute;
    margin: auto;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: ' ';
    display: inline-block;
    background: center center no-repeat;
    background-size: cover;
    border-radius: 50%;
    border-style: solid;
    border-width: 0.15em;
    background-clip: padding-box;
    -webkit-background-clip: padding-box;
    border-color: currentColor currentColor currentColor transparent;
    box-sizing: border-box;
}
.ld-ext-right {
    position: relative;
    transition: all 0.3s;
    transition-timing-function: ease-in;
    overflow: hidden;
}

.ld-ext-right.running {
    padding-right: 2.5em !important;
}

.ld.ld-spin {
    -webkit-animation: ld-spin 1s infinite;
    animation: ld-spin 1s infinite;
}

.ld-ext-right > .ld {
    top: 50%;
    left: auto;
    right: 1em;
    position: absolute;
    margin: -0.5em;
    opacity: 0;
    z-index: -100;
    transition: all 0.3s;
    transition-timing-function: ease-in;
}

.ld-ext-right.running > .ld {
    opacity: 1;
    z-index: auto;
    visibility: visible;
}
